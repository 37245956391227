<template>
  <div class="bg-white">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12">
        <div
          class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
        >
          <h2 class="text-3xl font-extrabold tracking-tight text-rp">
            SHOWCASE
          </h2>
        </div>
        <ul
          role="list"
          class="
            space-y-12
            sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0
            lg:grid-cols-3 lg:gap-x-8
          "
        >
          <!-- NEW IMAGES -->
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/District8-1.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/District8-13.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/District8-26.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/Circus18.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <!--  -->
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/Circus20.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <!-- -->
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/Circus25.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <!--  -->

          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/KerriChandler.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <!-- OLD IMAGES -->
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/BOFF2.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/BOFF1.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/ACA2.jpg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/WV2.jpeg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/WV3.jpeg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="@/assets/ACA4.jpeg"
                  alt=""
                  data-aos="fade-up"
                />
              </div>
            </div>
          </li>

          <!-- More people... -->
        </ul>
      </div>
    </div>
  </div>
</template>
