<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-black">
    <div class="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
      <div class="h-full w-full xl:grid xl:grid-cols-2">
        <div class="h-full xl:relative xl:col-start-2">
          <img
            class="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
            src="@/assets/WV1.jpeg"
            alt="People working on laptops"
            data-aos="fade-up"
          />
          <div
            aria-hidden="true"
            class="
              absolute
              inset-x-0
              top-0
              h-32
              bg-gradient-to-b
              from-black
              xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r
            "
          />
        </div>
      </div>
    </div>
    <div
      class="
        max-w-4xl
        mx-auto
        px-4
        sm:px-6
        lg:max-w-7xl lg:px-8
        xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8
      "
    >
      <div
        class="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24"
      >
        <h2 class="text-3xl font-extrabold tracking-tight text-rr">
          Key Achievements
        </h2>
        <div class="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
          <p v-for="item in metrics" :key="item.id">
            <span class="block text-2xl font-bold text-white">{{
              item.stat
            }}</span>
            <span class="mt-1 block text-base text-gray-300"
              ><span class="font-medium text-white">{{ item.emphasis }}</span>
              {{ item.rest }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const metrics = [
  {
    id: 1,
    stat: "200K+",
    rest: "Selling 200,000+ tickets from February 2022 to February 2022 across two clubs and multiple festivals.",
  },
  {
    id: 2,
    stat: "VIRAL CAMPAIGNS",
    rest: "Creating a year long viral campaign using the Academy’s sign on the outside of the building. Cheering Ireland up weekly throughout the pandemic.",
  },
  {
    id: 3,
    stat: "11K+",
    rest: "Selling over 11,000 tickets for Ireland's biggest one dance music festival - Life Festival.",
  },
];

export default {
  setup() {
    return {
      metrics,
    };
  },
};
</script>
