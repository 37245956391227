<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-black">
    <main>
      <div>
        <!-- Hero card -->
        <div class="relative">
          <div class="absolute inset-x-0 bottom-0 h-1/2 bg-black" />
          <div class="full-w mx-auto">
            <div class="relative shadow-xl sm:overflow-hidden">
              <div class="absolute inset-0">
                <img
                  class="h-full w-full object-cover opacity-50"
                  src="@/assets/District8-1.jpg"
                  alt="People working on laptops"
                />
                <div class="absolute inset-0 bg-grey-700 mix-blend-multiply" />
              </div>
              <div class="relative px-4 py-8 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <img
                  class="mx-auto lg:block mt-20 h-25 w-auto"
                  src="@/assets/RYANROE.png"
                  alt="Workflow"
                  data-aos="fade-up"
                />

                <div
                  class="
                    mt-10
                    sm:mb-10
                    pb-10
                    max-w-sm
                    mx-auto
                    sm:max-w-none sm:flex sm:justify-center
                  "
                >
                  <!-- <div
                    class="
                      space-y-4
                      sm:space-y-0
                      sm:mx-auto
                      sm:inline-grid
                      sm:grid-cols-2
                      sm:gap-5
                    "
                  >
                    <a
                      href="#About"
                      class="
                        flex
                        items-center
                        justify-center
                        px-4
                        py-3
                        border border-transparent
                        text-base
                        font-medium
                        shadow-sm
                        text-white
                        rounded-md
                        bg-rr
                        hover:bg-rp
                        sm:px-8
                      "
                      data-aos="fade-in"
                    >
                      About
                    </a>
                    <a
                      href="#Contact"
                      class="
                        flex
                        items-center
                        justify-center
                        px-4
                        py-3
                        border border-transparent
                        text-base
                        font-medium
                        rounded-md
                        shadow-sm
                        text-white
                        bg-rp
                        hover:bg-rr
                        sm:px-8
                      "
                      data-aos="fade-in"
                    >
                      Contact
                    </a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Logo cloud -->
        <div class="bg-black">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-cols-6">
              <div
                class="
                  col-span-1
                  flex
                  justify-center
                  md:col-span-2
                  lg:col-span-1
                "
                data-aos="fade-up"
              >
                <img class="h-17" src="@/assets/BOFFLOGO.png" alt="Tuple" />
              </div>
              <div
                class="
                  col-span-1
                  flex
                  justify-center
                  md:col-span-2
                  lg:col-span-1
                "
                data-aos="fade-up"
              >
                <img class="h-17" src="@/assets/INDLOGO.png" alt="Tuple" />
              </div>
              <div
                class="
                  col-span-1
                  flex
                  justify-center
                  md:col-span-2
                  lg:col-span-1
                "
                data-aos="fade-up"
              >
                <svg
                  class="mt-10 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="414"
                  height="103.771"
                  viewBox="0 0 414 103.771"
                >
                  <g
                    id="Group_7"
                    data-name="Group 7"
                    transform="translate(4473 -1935)"
                  >
                    <g
                      id="Group_6"
                      data-name="Group 6"
                      transform="translate(-4473 1935)"
                    >
                      <g
                        id="Group_4"
                        data-name="Group 4"
                        transform="translate(331.871)"
                      >
                        <path
                          id="Path_12"
                          data-name="Path 12"
                          d="M-4343.97,1961.038a41.425,41.425,0,0,0-6.533,8.526,40.954,40.954,0,0,0-4.106,9.937,41.36,41.36,0,0,0-1.391,10.663h20.533a20.7,20.7,0,0,1,.7-5.333,20.387,20.387,0,0,1,2.054-4.967,20.537,20.537,0,0,1,3.265-4.264l-8.027-8.053Z"
                          transform="translate(4356 -1887.181)"
                          fill="#fff"
                        />
                        <path
                          id="Path_13"
                          data-name="Path 13"
                          d="M-4279.369,2017.384a41.255,41.255,0,0,0-6.533-8.526q-.91-.915-1.876-1.77a41.171,41.171,0,0,0-6.623-4.782,40.89,40.89,0,0,0-7.451-3.378q-1.211-.408-2.453-.74a40.9,40.9,0,0,0-5.025-1.016,41.553,41.553,0,0,0-5.605-.38,20.423,20.423,0,0,1-5.315-.7,20.349,20.349,0,0,1-4.953-2.057,21.069,21.069,0,0,1-1.781-1.163,20.816,20.816,0,0,1-2.468-2.113,20.625,20.625,0,0,1-3.265-4.263,20.424,20.424,0,0,1-2.054-4.97,20.668,20.668,0,0,1-.7-5.333,20.661,20.661,0,0,1,.7-5.33,20.413,20.413,0,0,1,2.054-4.969,20.537,20.537,0,0,1,3.265-4.264,20.643,20.643,0,0,1,4.249-3.276,20.453,20.453,0,0,1,4.953-2.06,20.548,20.548,0,0,1,5.315-.7,20.548,20.548,0,0,1,5.315.7,20.453,20.453,0,0,1,4.953,2.06,20.74,20.74,0,0,1,4.252,3.276,20.775,20.775,0,0,1,3.265,4.264,20.521,20.521,0,0,1,2.051,4.969,20.661,20.661,0,0,1,.7,5.33,20.669,20.669,0,0,1-.7,5.333,20.533,20.533,0,0,1-2.051,4.97,20.864,20.864,0,0,1-3.265,4.263l8.026,8.053,6.487,6.51a41.411,41.411,0,0,0,6.533-8.526,41.08,41.08,0,0,0,4.106-9.937,41.28,41.28,0,0,0,1.391-10.665,41.4,41.4,0,0,0-1.391-10.663,41.142,41.142,0,0,0-4.1-9.936,41.308,41.308,0,0,0-6.532-8.527,41.084,41.084,0,0,0-8.5-6.552,40.731,40.731,0,0,0-9.9-4.119,41.074,41.074,0,0,0-10.631-1.4,41.043,41.043,0,0,0-10.629,1.4,40.77,40.77,0,0,0-9.905,4.119,41.142,41.142,0,0,0-8.5,6.552,41.3,41.3,0,0,0-6.53,8.527,40.867,40.867,0,0,0-4.1,9.936,41.234,41.234,0,0,0-1.394,10.663,41.364,41.364,0,0,0,1.391,10.665,41,41,0,0,0,4.106,9.937,41.582,41.582,0,0,0,6.533,8.526q.91.91,1.877,1.767a41.208,41.208,0,0,0,6.623,4.785,40.969,40.969,0,0,0,7.448,3.378q1.213.4,2.457.737a40.192,40.192,0,0,0,5.026,1.016,41.025,41.025,0,0,0,5.6.383,20.548,20.548,0,0,1,5.315.7,20.357,20.357,0,0,1,4.953,2.06,20.46,20.46,0,0,1,1.781,1.163,20.386,20.386,0,0,1,2.471,2.113,20.775,20.775,0,0,1,3.265,4.264,20.5,20.5,0,0,1,2.051,4.967,20.7,20.7,0,0,1,.7,5.333h20.532a41.275,41.275,0,0,0-1.391-10.663A41.032,41.032,0,0,0-4279.369,2017.384Z"
                          transform="translate(4356 -1935)"
                          fill="#fff"
                        />
                      </g>
                      <g
                        id="Group_5"
                        data-name="Group 5"
                        transform="translate(0 33.386)"
                      >
                        <path
                          id="Path_14"
                          data-name="Path 14"
                          d="M-4425.478,2015.9h-9.4v-5.478c-3.758,4.312-9.219,6.734-16.107,6.734-11.546,0-22.018-9.425-22.018-23.52,0-14.007,10.472-23.523,22.018-23.523,6.889,0,12.35,2.516,16.107,6.734V1946.77h9.4Zm-9.4-22.264c0-9.338-7.159-15.263-14.589-15.263-8.055,0-14.409,5.925-14.409,15.263,0,9.426,6.354,15.26,14.409,15.26C-4442.034,2008.9-4434.875,2003.061-4434.875,1993.635Z"
                          transform="translate(4473 -1946.77)"
                          fill="#fff"
                        />
                        <path
                          id="Path_15"
                          data-name="Path 15"
                          d="M-4440.713,1954.507a5.652,5.652,0,0,1-5.906,5.744,5.676,5.676,0,0,1-6-5.744,5.857,5.857,0,0,1,6-5.838A5.836,5.836,0,0,1-4440.713,1954.507Zm-10.562,57.9v-44.527h9.311v44.527Z"
                          transform="translate(4510.436 -1943.283)"
                          fill="#fff"
                        />
                        <path
                          id="Path_16"
                          data-name="Path 16"
                          d="M-4436.706,1986.87c.626,5.3,5.281,7.542,11.1,7.542,5.369,0,8.77-2.425,8.77-5.925,0-2.692-1.881-4.4-6-5.3l-9.667-1.977c-7.7-1.7-12.08-5.835-12.08-12.118,0-8.26,6.889-14.1,17.54-14.1,10.2,0,17.094,5.386,18.7,12.838l-8.858,1.886c-.717-4.309-4.388-7.273-10.114-7.273-5.457,0-7.874,2.695-7.874,5.837,0,2.513,1.432,4.13,5.369,5.027l10.56,2.334c7.159,1.526,11.546,5.747,11.546,12.029,0,9.338-7.608,14.364-18.705,14.364-9.936,0-18.437-4.217-19.6-13.2Z"
                          transform="translate(4522.562 -1931.658)"
                          fill="#fff"
                        />
                        <path
                          id="Path_17"
                          data-name="Path 17"
                          d="M-4394.912,2007.933c-4.208,2.513-7.248,3.409-11.01,3.409-9.576,0-16.018-5.386-16.018-16.429v-21.637h-9.579v-7.718h9.579v-15.624h9.308v15.624h15.214v7.718h-15.214v21.01c0,5.565,2.952,8.26,7.337,8.26a14.571,14.571,0,0,0,7.52-2.425Z"
                          transform="translate(4549.185 -1940.958)"
                          fill="#fff"
                        />
                        <path
                          id="Path_18"
                          data-name="Path 18"
                          d="M-4387.349,1955.611l-.628,8.978a24.742,24.742,0,0,0-2.6-.088c-11.273,0-16.824,7.9-16.824,20.108v15.711h-9.308v-44.527h9.308v9.514c3.578-5.926,9.218-10.053,16.916-10.053A9.664,9.664,0,0,1-4387.349,1955.611Z"
                          transform="translate(4576.388 -1931.191)"
                          fill="#fff"
                        />
                        <path
                          id="Path_19"
                          data-name="Path 19"
                          d="M-4392.792,1954.507a5.651,5.651,0,0,1-5.906,5.744,5.675,5.675,0,0,1-6-5.744,5.856,5.856,0,0,1,6-5.838A5.835,5.835,0,0,1-4392.792,1954.507Zm-10.562,57.9v-44.527h9.308v44.527Z"
                          transform="translate(4598.448 -1943.283)"
                          fill="#fff"
                        />
                        <path
                          id="Path_20"
                          data-name="Path 20"
                          d="M-4361.591,1984.088l8.591,2.246c-2.507,9.514-11.365,15.708-21.656,15.708-12.71,0-23.449-9.425-23.449-23.52A23.058,23.058,0,0,1-4374.657,1955c10.021,0,18.792,6.106,21.656,15.62l-8.948,2.6c-1.791-6.552-6.8-9.6-12.708-9.6-8.235,0-14.322,6.1-14.322,14.9,0,9.068,6.087,14.9,14.322,14.9C-4368.75,1993.425-4363.83,1990.373-4361.591,1984.088Z"
                          transform="translate(4610.543 -1931.658)"
                          fill="#fff"
                        />
                        <path
                          id="Path_21"
                          data-name="Path 21"
                          d="M-4344.825,2007.933c-4.211,2.513-7.251,3.409-11.013,3.409-9.576,0-16.018-5.386-16.018-16.429v-21.637h-9.579v-7.718h9.579v-15.624h9.308v15.624h15.214v7.718h-15.214v21.01c0,5.565,2.952,8.26,7.338,8.26a14.571,14.571,0,0,0,7.519-2.425Z"
                          transform="translate(4641.165 -1940.958)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div
                class="
                  col-span-1
                  flex
                  justify-center
                  md:col-span-2 md:col-start-2
                  lg:col-span-1
                "
                data-aos="fade-up"
              >
                <img class="h-17" src="@/assets/FCLOGO.png" alt="Tuple" />
              </div>
              <div
                class="
                  col-span-1
                  flex
                  justify-center
                  md:col-span-2 md:col-start-4
                  lg:col-span-1
                "
                data-aos="fade-up"
              >
                <img class="h-17" src="@/assets/WVLOGO.png" alt="Tuple" />
              </div>
              <div
                class="
                  col-span-1
                  flex
                  justify-center
                  md:col-span-2 md:col-start-4
                  lg:col-span-1
                "
                data-aos="fade-up"
              >
                <img class="h-17" src="@/assets/ACALOGO.png" alt="Tuple" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- More main page content here... -->
    </main>
  </div>
</template>

<script>
import {} from "@headlessui/vue";
import {
  AnnotationIcon,
  ChatAlt2Icon,
  InboxIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/outline";

const solutions = [
  {
    name: "Inbox",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: InboxIcon,
  },
  {
    name: "Messaging",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: AnnotationIcon,
  },
  {
    name: "Live Chat",
    description: "Your customers' data will be safe and secure.",
    href: "#",
    icon: ChatAlt2Icon,
  },
  {
    name: "Knowledge Base",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: QuestionMarkCircleIcon,
  },
];
const navigation = [
  { name: "Pricing", href: "#" },
  { name: "Partners", href: "#" },
  { name: "Company", href: "#" },
];

export default {
  setup() {
    return {
      solutions,
      navigation,
    };
  },
};
</script>
