<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-black">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12">
        <div
          class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
        >
          <h2 class="text-3xl font-extrabold tracking-tight text-rr">
            VIDEO SHOWCASE
          </h2>
        </div>

        <div class="flex">
          <div class="mx-2" data-aos="fade-up">
            <a
              class="text-white underline"
              href="https://www.instagram.com/reel/Cqc2-xwueGc/?utm_source=ig_web_copy_link"
              target="_blank"
              >Click here to view Bicep after movie!</a
            >
          </div>
          <div class="mx-2" data-aos="fade-up">
            <a
              class="text-white underline"
              href="https://www.instagram.com/reel/CodEk7HrkPF/?utm_source=ig_web_copy_link"
              target="_blank"
              >Click here to view the Academy after movie!</a
            >
          </div>
        </div>

        <ul
          role="list"
          class="
            space-y-12
            sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0
            lg:grid-cols-3 lg:gap-x-8
          "
        >
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2" data-aos="fade-up">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/C3fIUAAlTWM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  w-full
                ></iframe>
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2" data-aos="fade-up">
                <iframe
                  width="950"
                  height="536"
                  src="https://www.youtube.com/embed/9vtcjBbGhQQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </li>
          <li>
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2" data-aos="fade-up">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/ozceZNjENvU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </li>
          <!-- More people... -->
        </ul>
      </div>
    </div>
  </div>
</template>
