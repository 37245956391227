<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-32 sm:px-6 lg:px-8">
      <h2 class="text-3xl font-extrabold text-rp">Contact Me</h2>
      <p class="mt-2 text-lg text-gray-100 max-w-3xl"></p>
      <div class="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
        <div data-aos="flip-up">
          <h3 class="text-lg font-medium text-rp">Email</h3>
          <p class="mt-2 text-base text-gray-900">
            <span class="block">rcarey742@gmail.com</span>
          </p>
        </div>
        <div data-aos="flip-up">
          <h3 class="text-lg font-medium text-rp">LinkedIn</h3>

          <p class="mt-2 text-base text-gray-rp hover:text-rp">
            <span class="block"
              ><a
                href="https://www.linkedin.com/in/ryan-roe-75235a132/?originalSubdomain=ie"
                >Check out my LinkedIn</a
              ></span
            >
          </p>
        </div>
        <div data-aos="flip-up">
          <h3 class="text-lg font-medium text-rp">Instagram</h3>
          <p class="mt-2 text-base text-gray-900 hover:text-rp">
            <span class="block"
              ><a href="https://www.instagram.com/ryanroe_/?hl=en">
                @ryanroe_</a
              ></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
