<template>
  <div class="bg-black">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div
        class="
          bg-white
          rounded-lg
          shadow-xl
          overflow-hidden
          lg:grid lg:grid-cols-2 lg:gap-4
        "
      >
        <div
          class="
            pt-10
            pb-12
            px-6
            sm:pt-16 sm:px-16
            lg:py-16 lg:pr-0
            xl:py-20 xl:px-20
          "
        >
          <div class="lg:self-center">
            <h2 class="text-3xl font-extrabold text-rp sm:text-4xl">
              <span class="block">ABOUT ME</span>
            </h2>
            <p class="mt-4 text-lg leading-6 text-grey-100">
              I’m 25 years old and currently living and working in Dublin,
              Ireland. I have been in the nightlife industry almost 8 years and
              considering it a passion led career more than a job.
            </p>
            <p class="mt-4 text-lg leading-6 text-grey-100">
              Starting my club career at the age of 18, flyering and managing
              the door for some of Dublin's most well known student venues
              (Alchemy and Lafayettes) has helped me slowly shape a very unique
              career for myself within the industry in Dublin.
            </p>
            <p class="mt-4 text-lg leading-6 text-grey-100">
              From helping out to eventually running my own parties and events
              in these venues - I have since went from out and out club promoter
              to the Marketing and Events Manager for two of Dublin's biggest
              clubs (The Academy and Index)and formerly Dublin's biggest club
              (The Wright Venue). From here I have created one of Ireland's
              biggest ever student nights, Konspiracy, in partnership with Shine
              Belfast and Bedlam as well as go on to run both a Friday and
              Saturday in the Academy and also run a full season of programming
              with some of the biggest names in the game at Index. Both
              simultaneously from October to December 2021.
            </p>
            <p class="mt-4 text-lg leading-6 text-grey-100">
              The lessons learned through my now 8 years in the clubbing
              industry in Ireland have helped me carve a path to a full time
              career within nightlife. A very rare thing here in Ireland
              considering the shape of nightlife currently.
            </p>
            <p class="mt-4 text-lg leading-6 text-grey-100">
              As well as managing club socials and events, I also advise and
              help book some of Ireland biggest festivals, such as Life Festival
              and Boxed Off. As the pandemic hit, I picked up managing a duo
              from Dublin (formerly my resident DJs) called Obskur. Who have
              since went on to play Warehouse Project, Boxed Off and racked up
              over 30 million Spotify streams all within the Covid 19 pandemic.
            </p>
          </div>
        </div>
        <div class="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <img
            class="
              transform
              translate-x-6 translate-y-6
              rounded-md
              object-cover object-center
              sm:translate-x-16
              lg:translate-y-20
            "
            data-aos="fade-up"
            src="@/assets/ME1.jpg"
            alt="App screenshot"
          />
        </div>
      </div>
    </div>
  </div>
</template>
