<template>
  <Home />
  <Footer />
</template>

<script setup>
import Home from "./views/Home.vue";
import Footer from "./components/Footer.vue";
import AOS from "aos";

AOS.init({ duration: 1500 });
</script>
